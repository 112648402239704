<style>
.center {
  width: 100%;
  height: 1180px;
  background-image: url("~@/static/login.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

h1 {
  font-size: 30px;
  color: black;
}

.logon {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  /* position: relative;
		overflow: hidden; */
  width: 768px;
  max-width: 100%;
  min-height: 480px;
  margin-top: 20px;
  display: flex;
  background: -webkit-linear-gradient(right, #4284db, #29eac4);
}

.overlaylong {
  border-radius: 10px 0 0 10px;
  width: 50%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlaylongleft {
  border-radius: 0px 10px 10px 0px;
  width: 50%;
  height: 100%;
  background-color: #fff;
  transform: translateX(100%);
  transition: transform 0.6s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlaylongright {
  border-radius: 10px 0 0 10px;
  width: 50%;
  height: 100%;
  background-color: #fff;
  transform: translateX(0%);
  transition: transform 0.6s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlaytitle {
  border-radius: 0px 10px 10px 0px;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlaytitleH2 {
  font-size: 30px;
  color: #fff;
  margin-top: 20px;
}

.overlaytitleP {
  font-size: 15px;
  color: #fff;
  margin-top: 20px;
}

.overlaytitleleft {
  border-radius: 0px 10px 10px 0px;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(0%);
  transition: transform 0.6s ease-in-out;
}

.overlaytitleright {
  border-radius: 0px 10px 10px 0px;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-100%);
  transition: transform 0.6s ease-in-out;
}

.overlaytitle-Signin {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlaytitle-Signup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.buttongohs {
  width: 180px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 15px;
  text-align: center;
  line-height: 40px;
  margin-top: 40px;
}

.overlaylongH2 {
  font-size: 25px;
  color: black;
  /* width: 250px; */
}

.overlaylong-Signin {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlaylong-Signup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 10px 0;
  width: 240px;
}

h3 {
  font-size: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  /* overflow: hidden; */
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}
.avatar {
  width: 78px;
  height: 78px;
  display: block;
}
</style>

<template>
  <div class="center">
    <div class="logon">
      <div :class="overlaylong">
        <div class="overlaylong-Signin" v-if="disfiex == 0">
          <h2 class="overlaylongH2">登录</h2>

          <el-form ref="form" :model="info" label-width="80px">
            <el-form-item label="账号">
              <el-input
                v-model="info.username"
                placeholder="账号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="密码">
              <el-input
                v-model="info.password"
                type="password"
                placeholder="密码"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" round @click="toSignUp()"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="overlaylong-Signup" v-if="disfiex == 1">
          <h2 class="overlaylongH2">注册用户</h2>
          <el-form ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="头像">
              <el-upload
                class="avatar-uploader"
                action="http://localhost:8600/oss/uploadFile"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
              >
                <img v-if="form.image" :src="form.image" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="用户名" prop="phone">
              <el-input
                v-model="form.username"
                placeholder="用户名"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="密码">
              <el-input
                v-model="form.password"
                type="password"
                placeholder="密码"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码">
              <el-input
                v-model="form.password1"
                type="password"
                placeholder="确认密码"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" round @click="toSignIn()"
                >注册</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div :class="overlaytitle">
        <div class="overlaytitle-Signin" v-if="disfiex == 0">
          <h1 class="overlaytitleH2">就要一起乐</h1>
          <h2 class="overlaytitleH2">后台管理系统</h2>
          <!-- <div class="buttongohs" @click="Signin">注册</div> -->
        </div>
        <div class="overlaytitle-Signup" v-if="disfiex == 1">
          <h2 class="overlaytitleH2">欢迎使用</h2>
          <div class="buttongohs" @click="Signup">登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { enrollApi, loginApi } from "@/api/login/login";
export default {
  data() {
    return {
      overlaylong: "overlaylong",
      overlaytitle: "overlaytitle",
      disfiex: 0,
      form: {
        image: "",
      },
      info: {},
      rules: {
        phone: [
          {
            min: 3,
            max: 5,
            message: "长度在 3 到 5 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    //文件上传
    handleAvatarSuccess(res) {
      this.form.image = res.data;
    },
    Signin() {
      this.overlaylong = "overlaylongleft";
      this.overlaytitle = "overlaytitleright";
      setTimeout(() => {
        this.disfiex = 1;
      }, 200);
    },
    Signup() {
      this.overlaylong = "overlaylongright";
      this.overlaytitle = "overlaytitleleft";

      setTimeout(() => {
        this.disfiex = 0;
      }, 200);
    },

    //注册
    toSignUp() {
      loginApi(this.info).then((res) => {

        if (res.data.code == 200) {
          this.$notify({
            message: "登录成功",
            type: "success",
          });
          localStorage.setItem('token',res.data.data.Authorization)
          this.$router.push("/user/user");
          //存入session
          //界面跳转
        }
      });
    },
    
  },
};
</script>