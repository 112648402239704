<template>
  <div class="stati">
    <div class="stati-item">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="addVersionLog"
        >添加</el-button
      >
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="getFlushed"
        >刷新</el-button
      >

      <el-table :data="tableData" style="width: 100%" :cell-style="cellStyle" :header-cell-style="cellStyle">
        <el-table-column prop="id" label="id"> </el-table-column>
        <el-table-column prop="title" label="标题"> </el-table-column>
        <el-table-column prop="price" label="价格"> </el-table-column>
        <el-table-column prop="coins" label="到账金币数量">
        </el-table-column>
        <el-table-column prop="days" label="连续天数"> </el-table-column>
        <el-table-column prop="giveBill" label="赠送比例"> </el-table-column>
        <el-table-column prop="desc1" label="文案1"> </el-table-column>
        <el-table-column prop="desc2" label="文案2"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-dropdown
              size="mini"
              @command="(command) => getOperation(command, scope.row)"
            >
              <el-button size="mini" type="text" icon="el-icon-d-arrow-right"
                >更多</el-button
              >
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="update" icon="el-icon-circle-check"
                  >修改</el-dropdown-item
                >
                <el-dropdown-item command="deleted" icon="el-icon-user"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="form.pageSize"
          layout="total,  prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>

      <!-- 弹窗 -->
      <el-dialog title="修改信息" :visible.sync="dialogFormVisible">
        <el-form :model="info">
          <el-form-item label="ID">
            <el-input v-model="info.id" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="标题">
            <el-input v-model="info.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="价格">
            <el-input-number
              v-model="info.price"
              :precision="2"
              :step="0.1"
              :max="10000"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="到账金币数量">
            <el-input-number v-model="info.coins"></el-input-number>
          </el-form-item>
          <el-form-item label="连续天数">
            <el-input-number v-model="info.days"></el-input-number>
          </el-form-item>
          <el-form-item label="赠送比例">
            <el-input v-model="info.giveBill" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="文案1">
            <el-input v-model="info.desc1" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="文案2">
            <el-input v-model="info.desc2" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="info.createdAt"
               value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="更新时间">
            <el-date-picker
              v-model="info.updatedAt"
               value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="getUpdateCharge()"
            >确 定 修 改</el-button
          >
        </div>
      </el-dialog>

      <!-- 弹窗 -->
      <el-dialog title="添加信息" :visible.sync="dialogVisible">
        <el-form :model="info">
          <el-form-item label="标题">
            <el-input v-model="info.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="价格">
            <el-input-number
              v-model="info.price"
              :precision="2"
              :step="0.1"
              :max="10"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="到账金币数量">
            <el-input-number v-model="info.coins"></el-input-number>
          </el-form-item>
          <el-form-item label="连续天数">
            <el-input-number v-model="info.days"></el-input-number>
          </el-form-item>
          <el-form-item label="赠送比例">
            <el-input v-model="info.giveBill" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="文案1">
            <el-input v-model="info.desc1" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="文案2">
            <el-input v-model="info.desc2" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="getAddCharge()"
            >确 定 添 加</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
      <script>
import { charge2ListApi,addChargeApi } from "@/api/market/charge2";
import {
  updateChargeApi,
  getMarketChargeApi,
  delMarketChargeApi,
} from "@/api/market/charge";

export default {
  name: "menu-item",
  data() {
    return {
      dialogFormVisible: false, //弹窗
      dialogVisible: false, //弹窗
      total: 0,
      form: {
        inviteCode: "",
        mobile: "",
        pageSize: 10,
        pageNum: 1,
      },
      info: {
        id: "",
      },
      tableData: [{}],
    };
  },

  mounted() {
    document.title = "后台管理系统|" + this.$route.meta.title;
    this.getcharge2List();
  },
  watch: {
    $route: function (to) {
      document.title = "后台管理系统|" + to.meta.title;
    },
  },
  methods: {
    cellStyle() {
      return {'text-align': 'center'};
    },
    // //文件上传
    // handleAvatarSuccess(res) {
    //   this.info.imageUrl = res.data.url;
    // },
    // beforeAvatarUpload(file) {
    //   const fileType = file.type === "image";
    //   if (!fileType) {
    //     this.$message.error("上传头像图片只能是 JPG 格式!");
    //   }
    //   return fileType;
    // },
    //添加dialog
    addVersionLog() {
      this.dialogVisible = true;
      this.info = {};
    },
    //添加方法
    getAddCharge() {
      this.dialogVisible = false;
      addChargeApi(this.info).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "数据添加成功",
            type: "success",
          });
          this.getcharge2List();
        }
      });
    },

    //删除
    getDelCharge(id) {
      delMarketChargeApi(id).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "删除成功",
            type: "success",
          });
          this.getcharge2List();
        }
      });
    },
    //修改
    getUpdateCharge() {
      this.dialogFormVisible = false;
      updateChargeApi(this.info).then((res) => {
        if (res.data.code == 200) {
          this.$notify({
            message: "数据修改成功",
            type: "success",
          });
          this.getcharge2List();
        }
      });
    },
    //回显
    getMarketCharge(id) {
      this.dialogFormVisible = true;
      getMarketChargeApi(id).then((res) => {
        if (res.data.code == 200) {
          this.info = res.data.data;
          this.$notify({
            message: "数据显示成功",
            type: "success",
          });
          this.getcharge2List();
        }
      });
    },
    getFlushed(){
      this.form.pageNum = 1;
      this.form.pageSize = 10;
      this.getcharge2List();
    },
    //列表
    getcharge2List() {
      charge2ListApi(this.form).then((res) => {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },

    //分页
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getcharge2List();
    },
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getcharge2List();
    },
    //操作功能
    getOperation(command, row) {
      switch (command) {
        case "update":
          //回显
          this.getMarketCharge(row.id);
          break;
        case "deleted":
          //状态正常
          this.getDelCharge(row.id);
          break;
        default:
          break;
      }
    },
  },
};
</script>
      
      <style scoped>
.el-card {
  /* margin-bottom: 20px; */
  text-align: center;
  margin-left: 60px;
}
.stati-item {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 40px;
}
.stati {
  width: 100%;
  height: 100%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-button{
  margin-left: 20px;
  margin-bottom: 20px;
}
</style>